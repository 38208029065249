// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-1 {
  position: absolute;
  width: 64px;
  height: 64px;
  margin: auto;
  top: 0px;
  left: 0;
  right: 0px;
  bottom: 25%;
}
.loader-1 {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.05);
  z-index: 999;
}
.loading-1 div {
  position: absolute;
  background: #212529;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading-1 div:nth-child(2) {
  animation-delay: -0.7s;
}
@keyframes loading-1 {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/loader/orderLoader/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,QAAQ;EACR,OAAO;EACP,UAAU;EACV,WAAW;AACb;AACA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,UAAU;EACV,WAAW;EACX,+BAA+B;EAC/B,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,UAAU;EACV,kBAAkB;EAClB,6DAA6D;AAC/D;AACA;EACE,sBAAsB;AACxB;AACA;EACE;IACE,SAAS;IACT,UAAU;IACV,QAAQ;IACR,SAAS;IACT,UAAU;EACZ;EACA;IACE,SAAS;IACT,UAAU;IACV,WAAW;IACX,YAAY;IACZ,UAAU;EACZ;AACF","sourcesContent":[".loading-1 {\n  position: absolute;\n  width: 64px;\n  height: 64px;\n  margin: auto;\n  top: 0px;\n  left: 0;\n  right: 0px;\n  bottom: 25%;\n}\n.loader-1 {\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  right: 0px;\n  bottom: 0px;\n  background: rgba(0, 0, 0, 0.05);\n  z-index: 999;\n}\n.loading-1 div {\n  position: absolute;\n  background: #212529;\n  opacity: 1;\n  border-radius: 50%;\n  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;\n}\n.loading-1 div:nth-child(2) {\n  animation-delay: -0.7s;\n}\n@keyframes loading-1 {\n  0% {\n    top: 28px;\n    left: 28px;\n    width: 0;\n    height: 0;\n    opacity: 1;\n  }\n  100% {\n    top: -1px;\n    left: -1px;\n    width: 58px;\n    height: 58px;\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
