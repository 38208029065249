import React from 'react';
import { Link } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from 'react-pro-sidebar';
import logo from '../assets/images/logo.png';
import smallLogo from '../assets/images/small-logo.png';
import 'react-pro-sidebar/dist/css/styles.css';
import { BsCardChecklist } from 'react-icons/bs';
import { CiPercent } from 'react-icons/ci';
import { FaUsers } from 'react-icons/fa6';

const NavigationMenu = (props) => {
  return (
    <>
      <div className="sidebarmenubar">
        <div className="sidebarmenulist">
          <ProSidebar className={props.menuCollapse ? null : 'collapsed'}>
            <SidebarHeader>
              {props.menuCollapse ? (
                <div className="logo-field">
                  <img src={logo} alt="logo" />
                </div>
              ) : (
                <p className="small-logo">
                  <img src={smallLogo} alt=""></img>
                </p>
              )}
            </SidebarHeader>
            <SidebarContent>
              <Menu iconShape="square">
                <MenuItem
                  active={window.location.pathname === '/users'}
                  icon={<FaUsers />}
                >
                  Users
                  <Link to="/users" />
                </MenuItem>
              </Menu>
              <Menu iconShape="square">
                <MenuItem
                  active={window.location.pathname === '/order-list'}
                  icon={<BsCardChecklist />}
                >
                  Orders
                  <Link to="/order-list" />
                </MenuItem>
              </Menu>
              <Menu iconShape="square">
                <MenuItem
                  active={window.location.pathname === '/add-margin'}
                  icon={<CiPercent />}
                >
                  Margin
                  <Link to="/add-margin" />
                </MenuItem>
              </Menu>
            </SidebarContent>
          </ProSidebar>
        </div>
      </div>
    </>
  );
};

export default NavigationMenu;
