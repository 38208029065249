import React, { useState } from "react";
import Layout from "../../layout/layout";
import * as authServices from "../../services/authServices";
import { createNotification } from "../../helper/notification";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { BsEye } from "react-icons/bs";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState([]);
  const [showOldPasswords, setShowOldPassword] = useState(true);
  const [showNewPasswords, setShowNewPassword] = useState(true);
  const [confirmShowNewPassword, setConfirmShowNewPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async () => {
    const formValidation = () => {
      const pwd = new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
      );
      let formErrors = {};
      let isValid = true;
      if (!oldPassword) {
        isValid = false;
        formErrors["oldPassword"] = "Old Password is Required";
      }
      if (!newPassword.trim()) {
        isValid = false;
        formErrors["newPassword"] = "New Password is Required";
      } else if (!pwd.test(newPassword)) {
        isValid = false;
        formErrors["newPassword"] =
          "Your password should have at least one special character, digits, uppercase and lowercase character. Length: 8+ ch-ers.";
      }
      if (!confirmNewPassword.trim()) {
        isValid = false;
        formErrors["confirmNewPassword"] = "Confirm Password is required";
      } else if (newPassword !== confirmNewPassword) {
        isValid = false;
        formErrors["confirmNewPassword"] =
          "New Password and Confirm Password doesn't match";
      }
      setError(formErrors);
      return isValid;
    };
    if (formValidation()) {
      setIsLoading(false);
      try {
        const obj = {
          oldPassword: oldPassword,
          newPassword: newPassword,
        };
        const response = await authServices.changePassword(obj);
        if (response.status == 200) {
          setIsLoading(true);
          createNotification("success", response.data.message);
          setTimeout(() => {
            navigate("/profile");
          }, 3000);
        } else if (response.status == 201) {
          createNotification("error", response.data.message);
        }
      } catch (err) {
        createNotification("error", err);
      }
    }
  };
  const handleOldPassword = () => {
    setShowOldPassword(!showOldPasswords);
  };
  const handleNewPassword = () => {
    setShowNewPassword(!showNewPasswords);
  };
  const handleConfirmNewPassword = () => {
    setConfirmShowNewPassword(!confirmShowNewPassword);
  };
  return (
    <>
      <Layout>
        <div className="profile-login-layout">
          <div className="user-profile-inner">
            <div className="heading-tilte">
              <h3>Change Password</h3>
            </div>
            <div className="profilelogin">
              <div className="popup-form-box">
                <div className="form-filed_course">
                  <label>Old Password</label>
                  <div className="pas-word">
                    <input
                      type={showOldPasswords ? "password" : "input"}
                      name="oldPassword"
                      value={oldPassword}
                      placeholder="Old Password"
                      onChange={(e) => {
                        setOldPassword(e.target.value);
                      }}
                    />

                    <Button
                      className={
                        showOldPasswords
                          ? "password-btn notshow "
                          : "password-btn"
                      }
                      onClick={handleOldPassword}
                    >
                      <BsEye />
                    </Button>
                  </div>
                  <span className="error">{error.oldPassword}</span>
                </div>

                <div className="form-filed_course">
                  <label>New Password</label>
                  <div className="pas-word">
                    <input
                      type={showNewPasswords ? "password" : "input"}
                      name="newPassword"
                      value={newPassword}
                      placeholder="New Password"
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                    />

                    <Button
                      className={
                        showNewPasswords
                          ? "password-btn notshow "
                          : "password-btn"
                      }
                      onClick={handleNewPassword}
                    >
                      <BsEye />
                    </Button>
                  </div>
                  <span className="error">{error.newPassword}</span>
                </div>

                <div className="form-filed_course">
                  <label>Confirm New Password</label>
                  <div className="pas-word">
                    <input
                      type={confirmShowNewPassword ? "password" : "input"}
                      name="confirmShowNewPassword"
                      value={confirmNewPassword}
                      placeholder="Confirm New Password"
                      onChange={(e) => {
                        setConfirmNewPassword(e.target.value);
                      }}
                    />
                    <Button
                      className={
                        confirmShowNewPassword
                          ? "password-btn notshow "
                          : "password-btn"
                      }
                      onClick={handleConfirmNewPassword}
                    >
                      <BsEye />
                    </Button>
                  </div>
                  <span className="error">{error.confirmNewPassword}</span>
                </div>
              </div>
              {isLoading == true ? (
                <button className="button btn-save">Loading</button>
              ) : (
                <button className="button btn-save" onClick={handleChange}>
                  Save Changes
                </button>
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </Layout>
    </>
  );
};

export default ChangePassword;
