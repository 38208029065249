import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as authService from "../../services/authServices";
import { Link, useParams } from "react-router-dom";
import { createNotification } from "../../helper/notification";
import { ToastContainer } from "react-toastify";
import { Button } from "react-bootstrap";
import { BsEye } from "react-icons/bs";
import BrandLogo from "./../../assets/images/logo.png";

const ResetPassword = (props) => {
  const [showPasswords, setShowPassword] = useState(true);
  const [showConfirmPasswords, setShowConfirmPassword] = useState(true);
  const navigate = useNavigate();
  const { token } = useParams();
  const [error, setError] = useState({});
  const [data, setData] = useState({
    password: "",
    resetLink: token,
  });
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleShowPassword = () => setShowPassword(!showPasswords);
  const handleConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPasswords);

  const isFormValid = () => {
    let pwd = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
    );
    if (data.password == "") {
      setError({ password: "New password is required!" });
      return false;
    } else if (!pwd.test(data.password)) {
      setError({
        password:
          "Your password should have at least one special character, digits, uppercase and lowercase character. Length: 8+ ch-ers.",
      });
      return false;
    }

    if (confirmPassword == "") {
      setError({ confirmPassword: "confirm password is required!" });
      return false;
    } else if (!pwd.test(confirmPassword)) {
      setError({
        confirmPassword:
          "Your password should have at least one special character, digits, uppercase and lowercase character. Length: 8+ ch-ers.",
      });
      return false;
    } else {
      setError({});
      return true;
    }
    return true;
  };

  const handleSubmit = async () => {
    const isValid = isFormValid();
    if (isValid) {
      if (data.password != confirmPassword) {
        setError({
          ...error,
          confirmPassword: "Passwords does not match",
        });
      } else {
        try {
          const loginResponse = await authService.ResetAPI(data);
          if (loginResponse.status == 200) {
            createNotification("success", loginResponse.data.message);
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            createNotification("error", loginResponse.data.message);
          }
        } catch (error) {
          return error;
        }
      }
    }
  };

  const onchange = (event) => {
    setError({});
    const { name, value } = event.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="login-wrapper">
      <div className="portal-signup-contain">
        <div className="portal-side-left">
          <div class="portal-logo">
            <img src={BrandLogo} alt="Logo" />
          </div>
          <div className="login-form">
            <div className="loginn">
              <div className="loginbox">
                <h2>Reset Password</h2>
                <div className="form-group">
                  <label>Password</label>
                  <div className="pas-word">
                    <input
                      className="form-control"
                      type={showPasswords ? "password" : "input"}
                      id="password"
                      placeholder="New Password"
                      name="password"
                      onChange={(e) => {
                        onchange(e);
                      }}
                    />
                    <span className="form-error">{error.password}</span>
                    <Button
                      className={
                        showPasswords ? "password-btn notshow " : "password-btn"
                      }
                      onClick={handleShowPassword}
                    >
                      <BsEye />
                    </Button>
                  </div>
                </div>
                <div className="form-group">
                  <label>Confirm Password</label>
                  <div className="pas-word">
                    <input
                      className="form-control"
                      type={showConfirmPasswords ? "password" : "input"}
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <span className="form-error">{error.confirmPassword}</span>
                    <Button
                      className={
                        showConfirmPasswords
                          ? "password-btn notshow "
                          : "password-btn"
                      }
                      onClick={handleConfirmPassword}
                    >
                      <BsEye />
                    </Button>
                  </div>
                </div>

                <div className="mt-3">
                  <button
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    onClick={handleSubmit}
                  >
                    Reset Password
                  </button>
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  <div className="form-check"></div>
                  <Link to="/" className="auth-link text-black">
                    Sign in to continue
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResetPassword;
