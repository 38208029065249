import React, { useState, useEffect } from 'react';
import Layout from '../../layout/layout';
import ReactPagination from '../pagination';
import * as UserServices from '../../services/usersServices';
import { ToastContainer } from 'react-toastify';
import SearchBar from '../searchBar/index';
import { createNotification } from '../../helper/notification';
import ChangePasswordModal from '../../modals/changePasswordModel';
import NoDataFound from '../dataFound';
import Loader from '../../loader';
import UsersTable from '../../table/userList';

const Users = () => {
  const [usersData, setUsersData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [searchInp, setSearchInp] = useState('');
  const [show, setShow] = useState(false);
  const [editId, setEditId] = useState();
  const [err, setErr] = useState({});
  const [add, setAdd] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dataFound, setDataFound] = useState(false);
  const [paginate, setPaginate] = useState(false);
  const [serialNumberVal, setSerialNumberVal] = useState();
  const [countValue, setCountValue] = useState(5);
  const [changePassword, setChangePassword] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const tokenResponse = localStorage.getItem('admin_token');

  const serialNumber = (activePage) => {
    let current_pageNo = activePage;
    let total_records_per_page = countValue ? countValue : pageLimit;
    let serial_num = total_records_per_page * (current_pageNo - 1);
    setSerialNumberVal(serial_num);
  };

  useEffect(() => {
    serialNumber();
  }, [activePage]);

  const handleClose = () => {
    setShow(false);
    setChangePassword({});
    setErr([]);
    setTimeout(() => {
      setAdd(false);
    }, 1000);
  };

  const handleShow = () => {
    setShow(true);
    setAdd(true);
  };

  const handlePageChange = (pageNumber) => {
    setTimeout(() => {
      setLoader(true);
      setActivePage(pageNumber);
    }, 1000);
    setLoader(false);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    let activePage = 1;
    const text = e.target.value;
    const textAfterTrim = text.trim();
    setSearchInp(textAfterTrim);
    if (textAfterTrim !== ' ') {
      if (text.length > 0) {
        const response = await UserServices.getAllSearcher(
          activePage,
          pageLimit,
          textAfterTrim,
        );
        if (response.data.totalCount <= 5) {
          setPaginate(false);
        }
        if (response?.data?.data?.length > 0) {
          setUsersData(response.data.data);
          setTotalCount(response?.data?.totalCount);
          setPaginate(true);
          serialNumber(activePage);
          setDataFound(false);
        } else {
          setDataFound(true);
          setUsersData([]);
          setPaginate(false);
        }
      }
      if (textAfterTrim == '') {
        setDataFound(false);
        if (countValue) {
          let pageActive = 1;
          setActivePage(pageActive);
          getUsersData(activePage, countValue, tokenResponse);
          setPaginate(true);
        } else {
          if ((activePage, pageLimit, tokenResponse)) {
            getUsersData(activePage, pageLimit, tokenResponse);
          }
        }
        setPaginate(true);
      }
      if (usersData == []) {
        setUsersData([]);
        setDataFound(true);
        setPaginate(false);
      }
    }
  };

  const handleEdit = async (id) => {
    handleShow();
    setEditId(id);
  };

  const validate = () => {
    let isValid = true;
    const formError = {};
    const passwordRegex =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;

    const { newPassword, confirmPassword } = changePassword;
    if (!newPassword) {
      isValid = false;
      formError['newPassword'] = 'New password is required.';
    } else if (newPassword.trim() == '') {
      isValid = false;
      formError['newPassword'] = 'Please enter a New password ';
    } else if (!passwordRegex.test(newPassword)) {
      isValid = false;
      formError['newPassword'] =
        'New password must have 8 to 16 characters, at least a symbol, upper, lower case letters and a number';
    }
    if (!confirmPassword) {
      isValid = false;
      formError['confirmPassword'] = 'Confirm password is required.';
    } else if (confirmPassword.trim() == '') {
      isValid = false;
      formError['confirmPassword'] = 'Please enter a Confirm password ';
    } else if (!passwordRegex.test(confirmPassword)) {
      isValid = false;
      formError['confirmPassword'] =
        'Confirm password must have 8 to 16 characters, at least a symbol, upper, lower case letters and a number';
    }
    setErr(formError);
    return isValid;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const { newPassword, confirmPassword } = changePassword;
    if (validate()) {
      if (newPassword == confirmPassword) {
        const response = await UserServices.changeUserPasswordByAdmin(
          editId,
          newPassword.trim(),
        );
        if (response.status == true) {
          createNotification('success', response.message);
        } else {
          createNotification('error', response.message);
        }
        handleClose();
      } else {
        return createNotification(
          'error',
          'New password and Confirm password can not be different.',
        );
      }
    }
  };

  const changeHandler = (e) => {
    setErr({});
    const { name, value } = e.target;
    setChangePassword({
      ...changePassword,
      [name]: value,
    });
  };

  const getUsersData = async () => {
    const response = await UserServices.getAllUsers(
      activePage,
      pageLimit,
      tokenResponse,
    );
    if (response?.data?.length > 0) {
      setDataFound(false);
      setLoader(false);
      setPaginate(true);
      setUsersData(response.data);
      serialNumber(activePage);
      setTotalCount(response.totalCount);
    } else if (response?.totalCount == 0) {
      setDataFound(true);
    }
  };

  useEffect(async () => {
    window.scrollTo({
      behavior: 'smooth',
      top: '0px',
    });
    const trimSearchText = searchInp.trim();
    if (trimSearchText) {
      if (countValue) {
        const response = await UserServices.getAllSearcher(
          activePage,
          countValue,
          trimSearchText,
        );
        setUsersData(response.data.data);
        setTotalCount(response.data.totalCount);
        serialNumber(activePage);
      } else {
        const response = await UserServices.getAllSearcher(
          activePage,
          pageLimit,
          trimSearchText,
        );
        setUsersData(response.data.data);
        setTotalCount(response.data.totalCount);
        serialNumber(activePage);
      }
    } else {
      if (countValue) {
        getUsersData(activePage, countValue, tokenResponse);
      } else {
        if ((activePage, pageLimit, tokenResponse)) {
          getUsersData(activePage, pageLimit, tokenResponse);
        }
      }
    }
  }, [activePage, tokenResponse, pageLimit]);

  useEffect(async () => {
    window.scrollTo({
      behavior: 'smooth',
      top: '0px',
    });
    if (countValue) {
      const trimText = searchInp.trim();
      if (trimText) {
        const response = await UserServices.getAllSearcher(
          activePage,
          countValue,
          trimText,
        );
        setUsersData(response.data.data);
        setTotalCount(response.data.totalCount);
        serialNumber(activePage);
      } else {
        const trimText = searchInp.trim();
        if (trimText) {
          const response = await UserServices.getAllSearcher(
            activePage,
            pageLimit,
            trimText,
          );
          setUsersData(response.data.data);
          setTotalCount(response.data.totalCount);
          serialNumber(activePage);
        }
      }
    }
  }, [searchInp]);

  const handleChangeStatus = async ({ _id, isActive }) => {
    const response = await UserServices.changeStatus(_id, {
      isActive: !isActive,
    });

    getUsersData();
    if (response.data.isActive == false) {
      createNotification('success', 'User enabled');
    } else if (response.data.isActive == true) {
      createNotification('warning', 'User disabled');
    }
  };

  return (
    <Layout>
      <React.Fragment>
        <div className="warmup-content-wrappper">
          <div className="plan-wrapper">
            <SearchBar handleSearch={handleSearch} handleShow={handleShow} />
            <div className="plan-table">
              {loader ? (
                <Loader />
              ) : dataFound ? (
                <NoDataFound />
              ) : (
                <UsersTable
                  handleChangeStatus={handleChangeStatus}
                  usersData={usersData}
                  handleEdit={handleEdit}
                  serial_num={serialNumberVal}
                />
              )}
            </div>
          </div>
        </div>
        <div className="table-pagination">
          {paginate && (
            <ReactPagination
              activePage={activePage}
              pageLimit={pageLimit}
              totalCount={totalCount}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
        <ChangePasswordModal
          handleClose={handleClose}
          show={show}
          changeHandler={changeHandler}
          submitHandler={submitHandler}
          err={err}
        />
        <ToastContainer />
      </React.Fragment>
    </Layout>
  );
};

export default Users;
