import React from 'react';
import { Table } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import Switch from 'react-switch';

const UsersTable = ({
  usersData,
  handleEdit,
  serial_num,
  handleChangeStatus,
}) => {
  const filteredUsers = usersData.filter((user) => user.userType === 'user');

  const renderTableRows = () => {
    return filteredUsers.map((user, index) => (
      <tr key={index}>
        <td>{serial_num + index + 1}</td>
        <td>{user?.name}</td>
        <td>{user?.email}</td>
        <td>{user?.address}</td>
        <td>{user?.city}</td>
        <td>{user?.state}</td>
        <td>{user?.country}</td>
        <td>
          <i
            className="fa fa-pencil"
            aria-hidden="true"
            style={{
              marginRight: '10px',
              cursor: 'pointer',
            }}
            onClick={() => handleEdit(user._id)}
          ></i>
        </td>
        <td>
          <Switch
            onChange={() => handleChangeStatus(user)}
            checked={user.isActive}
            width={60}
            onColor="#212529"
          />
        </td>
      </tr>
    ));
  };

  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Name</th>
            <th>Email</th>
            <th>Address</th>
            <th>City</th>
            <th>State</th>
            <th>Country</th>
            <th>Change Password</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{renderTableRows()}</tbody>
      </Table>
      <ToastContainer />
    </>
  );
};

export default UsersTable;
