import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { PUBLIC_API_URL } from '../../constants/index';
import Layout from '../../layout/layout';
import * as userServices from '../../services/usersServices';
import { createNotification } from '../../helper/notification';
import { ToastContainer } from 'react-toastify';
import './profile.scss';
import { useNavigate } from 'react-router';

const Profile = () => {
  const navigate = useNavigate();
  const [error, setError] = useState([]);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  let handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setUser(() => {
      return {
        ...user,
        [name]: value,
      };
    });
  };

  const { firstName, lastName, email } = user;
  const handleEdit = async (e) => {
    const formValidation = () => {
      let formErrors = {};
      const letterRegex = new RegExp(/^[a-zA-Z]+$/);
      let isValid = true;
      if (!firstName) {
        isValid = false;
        formErrors['firstName'] = 'First Name is Required';
      } else if (firstName && !letterRegex.test(firstName)) {
        isValid = false;
        formErrors['firstName'] =
          'Number,Space and Special Characters are not allowed';
      }
      if (!lastName) {
        isValid = false;
        formErrors['lastName'] = 'Last Name is Required';
      } else if (lastName && !letterRegex.test(lastName)) {
        isValid = false;
        formErrors['lastName'] =
          'Number,Space and Special Character are not allowed';
      }
      setError(formErrors);
      return isValid;
    };

    if (formValidation()) {
      try {
        setIsLoading(true);
        const userId = localStorage.getItem('userId_');
        const response = await userServices.EditUser(userId, user);
        if (response.status == 200) {
          createNotification('success', response.data.message);
          setTimeout(() => {
            navigate('/profile');
            setIsLoading(false);
          }, 5350);
        }
      } catch (err) {
        createNotification('error', err.message);
      }
    }
  };

  const getAdmin = async () => {
    try {
      let token = localStorage.getItem('admin_token');
      let adminId = localStorage.getItem('userId_');
      const data = await axios.get(
        `${PUBLIC_API_URL}/admin/getUser/${adminId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setUser(data.data.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => getAdmin(), []);

  return (
    <>
      <Layout>
        <div className="profile-login-layout">
          <div className="user-profile-inner">
            <div className="heading-tilte">
              <h2>Edit Profile</h2>
            </div>
            <div className="profilelogin">
              <div className="form-group">
                <input
                  type="text"
                  name="firstName"
                  value={firstName}
                  placeholder="First Name"
                  onChange={handleChange}
                />
                <span className="error">{error?.firstName}</span>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="lastName"
                  value={lastName}
                  placeholder="Last Name"
                  onChange={handleChange}
                />
                <span className="error">{error?.lastName}</span>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="email"
                  disabled
                  value={email}
                  placeholder="email"
                  onChange={handleChange}
                />
              </div>
              {isLoading == true ? (
                <button className="button btn-save">Loading</button>
              ) : (
                <button className="button btn-save" onClick={handleEdit}>
                  Save Changes
                </button>
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </Layout>
    </>
  );
};

export default Profile;
