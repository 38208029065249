import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';

const MarginListTable = ({ getMarginData, handleDelete, handleEdit }) => {
  const tableRows = () => {
    const data =
      getMarginData &&
      getMarginData.length > 0 &&
      getMarginData.map((data, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>
            {data.marginType === '$'
              ? `${data.marginType}${data.marginValue}`
              : `${data.marginValue}${data.marginType}`}
          </td>
          <td>
            <i
              className="fa fa-pencil"
              aria-hidden="true"
              style={{
                marginRight: '10px',
                cursor: 'pointer',
              }}
              onClick={() => handleEdit(data, data._id)}
            ></i>
            <i
              className="fa fa-trash"
              aria-hidden="true"
              style={{
                marginLeft: '10px',
                cursor: 'pointer',
              }}
              onClick={() => handleDelete(data._id)}
            ></i>
          </td>
        </tr>
      ));
    return data;
  };

  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Margin</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{tableRows()}</tbody>
      </Table>
      <ToastContainer />
    </>
  );
};

export default MarginListTable;
