import React from "react";
import "./index.css";

const Loader = () => {
  return (
    <>
      <div className="loader">
        <div className="loading">
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  );
};
export default Loader;
