import React, { useState, useEffect } from 'react';
import Layout from '../../layout/layout';
import * as MarginServices from '../../services/marginServices';
import { ToastContainer } from 'react-toastify';
import SearchBar from '../searchBar';
import MarginListTable from '../../table/marginList';
import NoDataFound from '../dataFound';
import Loader from '../../loader';
import { createNotification } from '../../helper/notification';
import MarginModel from '../../modals/marginModel';

const MarginList = () => {
  const [getMarginData, setGetMarginData] = useState('');
  const [loader, setLoader] = useState(false);
  const [dataFound, setDataFound] = useState(false);
  const tokenResponse = localStorage.getItem('admin_token');
  const [show, setShow] = useState(false);
  const [add, setAdd] = useState(false);
  const [err, setErr] = useState({});
  const [addMarginData, setAddMarginData] = useState({
    marginValue: '',
    marginType: '',
  });
  const { marginValue, marginType } = addMarginData;
  const [edit, setEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [editId, setEditId] = useState();

  useEffect(() => {
    getData();
  }, []);

  const handleClose = () => {
    setShow(false);
    setAddMarginData({});
    setErr([]);
    setTimeout(() => {
      setEdit(false);
      setAdd(false);
      setDeleted(false);
    }, 1000);
  };

  const handleShow = () => {
    setShow(true);
    setAdd(true);
  };

  const handleDelete = async (id) => {
    setDeleted(true);
    setAdd(false);
    setEdit(false);
    setDeleteId(id);
    handleShow();
  };

  const validate = () => {
    let isValid = true;
    const formError = {};
    if (marginValue == null || marginValue == '') {
      isValid = false;
      formError['marginValue'] = 'Please enter margin value.';
    } else if (isNaN(parseFloat(marginValue)) || parseFloat(marginValue) <= 0) {
      isValid = false;
      formError['marginValue'] = 'Margin value must be greater than zero';
    } else if (!/^\d+(\.\d{1,2})?$/.test(marginValue)) {
      isValid = false;
      formError['marginValue'] =
        'Please enter a valid number with up to two decimal places.';
    }

    if (marginType == '') {
      isValid = false;
      formError['marginType'] = 'Please select your product margin type.';
    }

    setErr(formError);
    return isValid;
  };

  const handleEdit = async (data, id) => {
    handleShow();
    setEdit(true);
    setEditId(id);
    setAddMarginData(data);
  };

  const changeHandler = (e) => {
    setErr({});
    const { name, value } = e.target;
    {
      add
        ? setAddMarginData((prevData) => ({
            ...prevData,
            [name]: value,
          }))
        : edit
          ? setAddMarginData((prevData) => ({
              ...prevData,
              [name]: value,
            }))
          : null;
    }
  };

  const getData = async () => {
    const response = await MarginServices.getMarginData();
    if (response?.data?.length > 0) {
      setDataFound(false);
      setIsAdd(true);
      setLoader(false);
      setGetMarginData(response.data);
    } else if (response?.data?.length == 0) {
      setDataFound(true);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validate()) {
      let response;
      if (edit) {
        response = await MarginServices.editMarginValue(
          editId,
          addMarginData,
          tokenResponse,
        );
      } else {
        response = await MarginServices.addMargin(addMarginData, tokenResponse);
      }
      if (response?.status == 200) {
        if (response?.data) {
          createNotification('success', response?.data?.message);
        } else {
          createNotification('success', response?.message);
        }
        getData();
        handleClose();
      } else {
        createNotification('error', response?.data?.message);
      }
    }
  };

  const handleRemove = async () => {
    const response = await MarginServices.removeData(deleteId);
    if (response.status == 200) {
      createNotification('success', response.data.message);
      setIsAdd(false);
      handleClose();
      getData();
    }
  };

  return (
    <Layout>
      <React.Fragment>
        <div className="warmup-content-wrappper">
          <div className="plan-wrapper">
            <SearchBar handleShow={handleShow} isAdd={isAdd} />
            <div className="plan-table">
              {loader ? (
                <Loader />
              ) : dataFound ? (
                <NoDataFound />
              ) : (
                <MarginListTable
                  getMarginData={getMarginData}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                />
              )}
            </div>
          </div>
        </div>
        <MarginModel
          show={show}
          addMarginData={addMarginData}
          err={err}
          changeHandler={changeHandler}
          submitHandler={submitHandler}
          deleted={deleted}
          handleRemove={handleRemove}
          handleClose={handleClose}
          edit={edit}
        />
        <ToastContainer />
      </React.Fragment>
    </Layout>
  );
};

export default MarginList;
