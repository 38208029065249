import axios from 'axios';
import { PUBLIC_API_URL } from '../constants/index';

export const loginUser = async (user) => {
  return await axios.post(`${PUBLIC_API_URL}/admin/login`, { ...user });
};

export const ForgotAPI = async (user) => {
  return await axios.post(`${PUBLIC_API_URL}/admin/forgot`, { ...user });
};

export const ResetAPI = async (data) => {
  return await axios.post(`${PUBLIC_API_URL}/admin/reset-password`, data, {
    headers: { 'Content-Type': 'application/json' },
  });
};

export const changePassword = async (obj) => {
  try {
    let token = localStorage.getItem('admin_token');
    let Id = localStorage.getItem('userId_');
    const data = {
      oldPassword: obj.oldPassword,
      newPassword: obj.newPassword,
      userId: Id,
      token,
    };
    const response = await axios.post(
      `${PUBLIC_API_URL}/admin/changePassword`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response;
  } catch (err) {
    return err;
  }
};
