window.process = {};
import React from 'react';
import './App.css';
import './assets/css/style.css';
import './assets/vendors/typicons/typicons.css';
import './assets/vendors/font-awesome/css/font-awesome.min.css';
import './assets/scss/main.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/login';
import Profile from './pages/profile';
import ResetPassword from './pages/resetPassword';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './hoc/privateRouter';
import ChangePassword from './pages/profile/changePassword';
import PublicRoute from './hoc/publicRouters';
import Users from './pages/users';
import OrderList from './pages/orderList';
import MarginList from './pages/margin';

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route
            isAuth={true}
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            isAuth={true}
            path="/order-list"
            element={
              <PrivateRoute>
                <OrderList />
              </PrivateRoute>
            }
          />
          <Route
            isAuth={true}
            path="/add-margin"
            element={
              <PrivateRoute>
                <MarginList />
              </PrivateRoute>
            }
          />
          <Route
            isAuth={true}
            path="/users"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path="/"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          ></Route>
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route
            path="/change-password"
            element={
              <PrivateRoute>
                <ChangePassword />
              </PrivateRoute>
            }
          ></Route>
        </Routes>
      </Router>
    </div>
  );
};

export default App;
