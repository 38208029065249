import React, { useState } from 'react';
import { Form, Button, Col, Row, Modal } from 'react-bootstrap';
import { SketchPicker } from 'react-color';

const MarginModal = ({
  handleClose,
  show,
  edit,
  addMarginData,
  changeHandler,
  submitHandler,
  err,
  deleted,
  handleRemove,
}) => {
  const { marginValue, marginType } = addMarginData;
  return (
    <Modal show={show} className="user-modal" onHide={handleClose}>
      <div className="login1">
        <Modal.Header>
          <Modal.Title>
            {edit ? 'Update Margin' : deleted ? 'Delete Margin' : 'Add Margin'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleted ? (
            <div className="popup-form-box">
              <h2 style={{ color: 'red' }}>Warning !!!</h2>
              <strong>Do you want to Delete this value ? </strong>
            </div>
          ) : (
            <Form>
              <Row>
                <Col xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Margin</Form.Label>
                    <Form.Control
                      type="number"
                      name="marginValue"
                      placeholder="Enter your value"
                      value={marginValue}
                      onChange={changeHandler}
                    />
                    <span className="error" style={{ color: 'red' }}>
                      {err && err.marginValue}
                    </span>
                  </Form.Group>
                  <Form.Select
                    className="mb-3"
                    name="marginType"
                    onChange={(e) => changeHandler(e)}
                    value={marginType}
                  >
                    <Form.Label>Type</Form.Label>
                    <option value="">Select your type</option>
                    <option value="$">Dollar</option>
                    <option value="%">Percentage</option>
                  </Form.Select>
                  <span className="error" style={{ color: 'red' }}>
                    {err && err.marginType}
                  </span>
                </Col>
              </Row>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          {deleted ? (
            <>
              <Button variant="info" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleRemove}>
                Delete
              </Button>
            </>
          ) : (
            <>
              <Button variant="info" onClick={handleClose}>
                Close
              </Button>
              <Button variant="danger" onClick={submitHandler}>
                {edit ? 'Update' : 'Add'}
              </Button>
            </>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default MarginModal;
