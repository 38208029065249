import React from 'react';
import { Form, Button, Col, Row, Modal } from 'react-bootstrap';

const ChangePasswordModal = ({
  handleClose,
  show,
  changeHandler,
  submitHandler,
  err,
}) => {
  return (
    <Modal show={show} className="user-modal" onHide={handleClose}>
      <div className="login1">
        <Modal.Header>
          <Modal.Title>Do you want to change Password ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="text"
                    name="newPassword"
                    placeholder="Enter New password"
                    onChange={changeHandler}
                  />
                  <span className="error" style={{ color: 'red' }}>
                    {err && err.newPassword}
                  </span>
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="text"
                    name="confirmPassword"
                    onChange={changeHandler}
                    placeholder="Enter Confirm password"
                  />
                  <span className="error" style={{ color: 'red' }}>
                    {err && err.confirmPassword}
                  </span>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={submitHandler}>
            Submit
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
