import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <span className="text-center">
        Copyright © 2023{' '}
        <a href="/" className="text-muted" target="_blank">
          Printsy.ai{'  '}
        </a>
        . All rights reserved.
      </span>
    </footer>
  );
}

export default Footer;
