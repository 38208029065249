import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { AiOutlineEye } from 'react-icons/ai';
import UserDetailList from '../modals/userDetailList';
import OrderDetailList from '../modals/orderDetailList';
import OrderDetailsList from '../modals/orderDetails';

const OrderListTable = ({
  usersData,
  serial_num,
  sendOrderInPrintFul,
  getLatestOrderDetails,
  orderStatus,
  OrderLoader,
  loadingRows,
}) => {
  const [viewUser, setViewUser] = useState(false);
  const [orderView, setOrderView] = useState(false);
  const [orderDetailsView, setOrderDetailsView] = useState(false);
  const [userSingleData, setUserSingleData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [colorId, setColorId] = useState('');

  const handleViewUser = (user) => {
    setUserSingleData(user);
    setViewUser(true);
  };

  const handleViewOrderDetails = (details) => {
    setOrderDetails(details);
    setOrderDetailsView(true);
  };

  const handleViewOrder = (Order) => {
    setColorId(Order.productDetails[0].productColorId);
    setOrderData(Order);
    setOrderView(true);
  };

  const handleOrderHideUser = () => {
    setOrderView(false);
  };

  const handleViewHideUser = () => {
    setViewUser(false);
  };

  const handleHideOrderDetails = () => {
    setOrderDetailsView(false);
  };

  const tableRows = () => {
    const data =
      usersData &&
      usersData.length > 0 &&
      usersData.map((user, index) => (
        <tr key={index}>
          <td>{serial_num + index + 1}</td>
          <td>{user?.userOrderId}</td>
          <td>
            ${!isNaN(Number(user?.totalPrice)) && Number(user?.totalPrice)}
          </td>
          <td>{user?.userDetails?.userFullName}</td>
          <td>
            <AiOutlineEye
              style={{
                marginLeft: '10px',
                cursor: 'pointer',
              }}
              onClick={() => handleViewUser(user)}
            />
          </td>
          <td>
            <AiOutlineEye
              style={{
                marginLeft: '10px',
                cursor: 'pointer',
              }}
              onClick={() => handleViewOrder(user?.orderId)}
            />
          </td>
          <td>
            {loadingRows[user.userOrderId] ? (
              <button className="order_btn loader_button">
                <img className="" src="/images/loader.svg" alt="Loader" />
              </button>
            ) : (
              <>
                {user.isSended == false ? (
                  <button
                    className="order_btn"
                    onClick={() => sendOrderInPrintFul(user)}
                  >
                    Send
                  </button>
                ) : (
                  <AiOutlineEye
                    style={{
                      marginLeft: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleViewOrderDetails(user?.orderDetails[0])
                    }
                  />
                )}
              </>
            )}
          </td>
        </tr>
      ));
    return data;
  };

  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Order Id</th>
            <th>Total Price</th>
            <th>Order By</th>
            <th>User Details</th>
            <th>Order Details</th>
            <th>Send to Printful</th>
          </tr>
        </thead>
        <tbody>{tableRows()}</tbody>
      </Table>
      <ToastContainer />
      <UserDetailList
        userSingleData={userSingleData}
        viewUser={viewUser}
        handleViewHideUser={handleViewHideUser}
      />
      <OrderDetailList
        colorId={colorId}
        orderView={orderView}
        orderData={orderData}
        handleOrderHideUser={handleOrderHideUser}
        usersData={usersData}
      ></OrderDetailList>
      <OrderDetailsList
        orderDetailsView={orderDetailsView}
        handleHideOrderDetails={handleHideOrderDetails}
        orderDetails={orderDetails}
        getLatestOrderDetails={getLatestOrderDetails}
        orderStatus={orderStatus}
        OrderLoader={OrderLoader}
      />
    </>
  );
};

export default OrderListTable;
