import React from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const SearchBar = ({ handleSearch, handleShow, isAdd }) => {
  const { pathname } = useLocation();

  return (
    <div className="plan-table-heading">
      <h3>
        {pathname == '/users'
          ? 'Users'
          : pathname == '/order-list'
          ? 'Orders'
          : 'Margin'}
      </h3>
      <div className="search-add-btn">
        {location.pathname == '/add-margin' ? (
          isAdd ? (
            ''
          ) : (
            <Button onClick={handleShow}>
              <img src="images/plus_icon.svg" /> Add
            </Button>
          )
        ) : (
          <div className="search-bar">
            <input
              name="search"
              type="text"
              className="form-control"
              placeholder={
                pathname == '/users' ? 'Search User' : 'Search by Order Id'
              }
              onChange={handleSearch}
            />
            <i className="fa fa-search" aria-hidden="true"></i>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
