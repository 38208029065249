import axios from 'axios';
import { PUBLIC_API_URL } from '../constants/index';

const token = localStorage.getItem('admin_token');

export const getOrderData = async (pageNo, limit, token) => {
  const responseToken = token;
  try {
    if (responseToken) {
      const response = await axios.get(
        `${PUBLIC_API_URL}/admin/getAllOrderHistory/${pageNo}/${limit}`,
        {
          headers: { Authorization: `Bearer ${responseToken}` },
        },
      );
      if (response.status == 200) {
        return response.data;
      } else {
        return response.data;
      }
    }
  } catch (err) {
    return {
      data: '',
      status: 400,
      message: err.message,
    };
  }
};

export const getDataByVariantId = async (variantId) => {
  const responseToken = token;
  try {
    if (responseToken) {
      const response = await axios.get(
        `${PUBLIC_API_URL}/user/product/fetchSingleVariantImageByID/${variantId}`,
      );
      if (response.status == 200) {
        return response.data;
      } else {
        return response.data;
      }
    }
  } catch (err) {
    return {
      data: '',
      status: 400,
      message: err.message,
    };
  }
};

export const postOrderData = async (data) => {
  const responseToken = token;
  try {
    if (responseToken) {
      const response = await axios.post(
        `${PUBLIC_API_URL}/user/order/sendOrder`,
        {
          data,
        },
      );
      if (response.status == 200) {
        return response.data;
      } else {
        return response.data;
      }
    }
  } catch (err) {
    return {
      data: err?.response?.data,
      status: 400,
      message: err?.message,
    };
  }
};

export const searchByOrderId = async (pageNo, limit, text) => {
  try {
    const response = await axios.get(
      `${PUBLIC_API_URL}/user/order/orderSearch/${pageNo}/${limit}/${text}`,
    );
    if (response.status == 200) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (err) {
    return {
      data: '',
      status: 400,
      message: err.message,
    };
  }
};

export const getOrderStatus = async (id, token) => {
  const responseToken = token;
  try {
    if (responseToken) {
      const response = await axios.get(
        `${PUBLIC_API_URL}/user/order/updateStatus/${id}`,
        {
          headers: { Authorization: `Bearer ${responseToken}` },
        },
      );
      if (response.status == 200) {
        return response.data;
      } else {
        return response.data;
      }
    }
  } catch (err) {
    return {
      data: '',
      status: 400,
      message: err.message,
    };
  }
};
