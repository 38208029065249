import axios from 'axios';
import { PUBLIC_API_URL } from '../constants/index';

export const getAllUsers = async (pageNumber, pageLimit) => {
  try {
    let token = localStorage.getItem('admin_token');
    const response = await axios.get(
      `${PUBLIC_API_URL}/user/get/${pageNumber}/${pageLimit}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const EditUser = async (id, obj) => {
  try {
    let token = localStorage.getItem('admin_token');
    const response = await axios.put(
      `${PUBLIC_API_URL}/admin/profileUpdate/${id}`,
      obj,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    return response;
  } catch (err) {
    return err;
  }
};

export const changeStatus = async (id, obj) => {
  let token = localStorage.getItem('admin_token');
  const response = await axios.put(
    `${PUBLIC_API_URL}/user/change-status/${id}`,
    obj,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return response.data;
};

export const changeUserPasswordByAdmin = async (id, password) => {
  let token = localStorage.getItem('admin_token');
  const response = await axios.put(
    `${PUBLIC_API_URL}/admin/userChangePassword/${id}`,
    { newPassword: password },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return response.data;
};

export const getAllSearcher = async (pageNo, limit, text) => {
  try {
    const response = await axios.get(
      `${PUBLIC_API_URL}/user/search/${pageNo}/${limit}/${text}`,
    );
    if (response.status == 200) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (err) {
    return {
      data: '',
      status: 400,
      message: err.message,
    };
  }
};

export const getallUsers = async () => {
  try {
    let token = localStorage.getItem('admin_token');
    const response = await axios.get(
      `${PUBLIC_API_URL}/admin/users/getAllUserOnly`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const changeLoginStatus = async (obj) => {
  let token = localStorage.getItem('admin_token');
  const response = await axios.put(
    `${PUBLIC_API_URL}/frontend/user/loginStatus`,
    obj,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return response.data;
};
