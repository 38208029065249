import React from 'react';
import './index.css';

const OrderLoader = () => {
  return (
    <>
      <div className="loader-1">
        <div className="loading-1">
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  );
};
export default OrderLoader;
