import React, { useState, useEffect } from 'react';
import Layout from '../../layout/layout';
import ReactPagination from '../pagination';
import * as OrderServices from '../../services/orderService';
import { ToastContainer } from 'react-toastify';
import SearchBar from '../searchBar';
import OrderListTable from '../../table/orderList';
import NoDataFound from '../dataFound';
import Loader from '../../loader';
import { createNotification } from '../../helper/notification';

const OrderList = () => {
  const [usersData, setUsersData] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [dataFound, setDataFound] = useState(false);
  const [paginate, setPaginate] = useState(false);
  const [serialNumberVal, setSerialNumberVal] = useState();
  const [countValue, setCountValue] = useState(5);
  const tokenResponse = localStorage.getItem('admin_token');
  const [orderStatus, setOrderStatus] = useState();
  const [OrderLoader, setOrderLoader] = useState(false);
  const [loadingRows, setLoadingRows] = useState({});

  const serialNumber = (activePage) => {
    let current_pageNo = activePage;
    let total_records_per_page = countValue ? countValue : pageLimit;
    let serial_num = total_records_per_page * (current_pageNo - 1);
    setSerialNumberVal(serial_num);
  };

  useEffect(() => {
    serialNumber();
    getUsersData();
  }, [activePage]);

  const handlePageChange = (pageNumber) => {
    setTimeout(() => {
      setLoader(true);
      setActivePage(pageNumber);
    }, 1000);
    setLoader(false);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    let activePage = 1;
    const text = e.target.value;
    const textAfterTrim = text.trim();
    if (textAfterTrim !== ' ') {
      if (text.length > 0) {
        const response = await OrderServices.searchByOrderId(
          activePage,
          pageLimit,
          textAfterTrim,
        );
        if (response.data.totalCount <= 5) {
          setPaginate(false);
        }
        if (response?.data?.data?.length > 0) {
          setUsersData(response.data.data);
          setTotalCount(response?.data?.totalCount);
          setPaginate(true);
          serialNumber(activePage);
          setDataFound(false);
        } else {
          setDataFound(true);
          setPaginate(false);
        }
      }
      if (textAfterTrim == '') {
        setDataFound(false);
        if (countValue) {
          let pageActive = 1;
          setActivePage(pageActive);
          getUsersData(activePage, countValue, tokenResponse);
          setPaginate(true);
        } else {
          if ((activePage, pageLimit, tokenResponse)) {
            getUsersData(activePage, pageLimit, tokenResponse);
          }
        }
        setPaginate(true);
      }
      if (usersData == []) {
        setUsersData([]);
        setDataFound(true);
        setPaginate(false);
      }
    }
  };

  const getUsersData = async () => {
    const response = await OrderServices.getOrderData(
      activePage,
      pageLimit,
      tokenResponse,
    );
    if (response?.data?.length > 0) {
      setDataFound(false);
      setLoader(false);
      setPaginate(true);
      setUsersData(response.data);
      serialNumber(activePage);
      setTotalCount(response.totalCount);
    } else if (response?.totalCount == 0) {
      setDataFound(true);
    }
  };

  const sendOrderInPrintFul = async (data) => {
    setLoadingRows((prevState) => ({
      ...prevState,
      [data.userOrderId]: true,
    }));
    let obj = {
      productData: data?.productDetails,
      totalPrice: data?.totalPrice,
      orderId: data?.userOrderId,
      userId: data?.userId._id,
      userDetails: data?.userDetails,
    };
    const response = await OrderServices.postOrderData(obj);
    if (response?.data?.code == 200) {
      createNotification('success', 'Order sent to Printful successful');
      setLoadingRows((prevState) => ({
        ...prevState,
        [data.userOrderId]: false,
      }));
      getUsersData();
    } else {
      createNotification('error', response?.data?.message);
      setLoadingRows((prevState) => ({
        ...prevState,
        [data.userOrderId]: false,
      }));
    }
  };

  const getLatestOrderDetails = async (id) => {
    setOrderLoader(true);
    const response = await OrderServices.getOrderStatus(id, tokenResponse);
    if (response?.data?.code == 200) {
      setOrderStatus(response?.data?.result);
      setOrderLoader(false);
      createNotification('success', 'Status update successful');
    } else {
      setOrderLoader(false);
    }
  };

  return (
    <Layout>
      <React.Fragment>
        <div className="warmup-content-wrappper">
          <div className="plan-wrapper">
            <SearchBar handleSearch={handleSearch} />
            <div className="plan-table">
              {loader ? (
                <Loader />
              ) : dataFound ? (
                <NoDataFound />
              ) : (
                <OrderListTable
                  usersData={usersData}
                  serial_num={serialNumberVal}
                  sendOrderInPrintFul={sendOrderInPrintFul}
                  getLatestOrderDetails={getLatestOrderDetails}
                  orderStatus={orderStatus}
                  OrderLoader={OrderLoader}
                  loadingRows={loadingRows}
                />
              )}
            </div>
          </div>
        </div>
        <div className="table-pagination">
          {paginate && (
            <ReactPagination
              activePage={activePage}
              pageLimit={pageLimit}
              totalCount={totalCount}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
        <ToastContainer />
      </React.Fragment>
    </Layout>
  );
};

export default OrderList;
