import { Modal, Button } from 'react-bootstrap';
import { useState } from 'react';
import './index.css';
import * as authService from '../../services/authServices';
import { createNotification } from '../../helper/notification';

const ForgotPassword = (props) => {
  const [show, setShow] = useState(false);
  const [forgotPassword, setForgotPassword] = useState({
    email: '',
  });
  const [error, setError] = useState([]);

  const handleSubmit = async (e) => {
    if (formValidation()) {
      const loginResponse = await authService.ForgotAPI(forgotPassword);
      if (loginResponse.status == 200) {
        handleCloseOnCloseButton();
        setForgotPassword({ email: '' });
        createNotification('success', loginResponse.data.message);
      } else {
        handleCloseOnCloseButton();
        createNotification('error', loginResponse.data.message);
      }
    }
  };

  const formValidation = () => {
    const { email } = forgotPassword;
    let formErrors = {};
    let isValid = true;
    const regex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
    if (!email) {
      isValid = false;
      formErrors['emailError'] = 'Email is required';
    } else if (!regex.test(email)) {
      isValid = false;
      formErrors['emailError'] = 'Email is not valid';
    }
    setError(formErrors);
    return isValid;
  };

  const handleShow = () => setShow(true);
  const handleCloseOnCloseButton = () => {
    setShow(false);
    setError([]);
    setForgotPassword({ email: '' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForgotPassword({
      ...forgotPassword,
      [name]: value,
    });
  };

  return (
    <div>
      <Button className="forgetpassbtn" variant="info" onClick={handleShow}>
        Forgot Password ?
      </Button>

      <Modal show={show} onHide={handleCloseOnCloseButton}>
        <Modal.Header>
          <Modal.Title>Forgot your Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>
            Enter the email associated with your account, and we'll send you a
            link to reset your password
          </label>
          <input
            className="input1"
            type="email"
            name="email"
            placeholder="Email"
            value={forgotPassword.email}
            onChange={handleChange}
          />
          <p style={{ color: 'red', textAlign: 'left', paddingLeft: '8px' }}>
            {error.emailError}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOnCloseButton}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" onClick={handleSubmit}>
            Send Email
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
