import axios from 'axios';
import { PUBLIC_API_URL } from '../constants/index';

export const addMargin = async (marginData, token) => {
  try {
    const response = await axios.post(
      `${PUBLIC_API_URL}/admin/margin/addMargin`,
      marginData,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    return response;
  } catch (err) {
    return err;
  }
};

export const getMarginData = async () => {
  try {
    let token = localStorage.getItem('admin_token');
    const response = await axios.get(`${PUBLIC_API_URL}/admin/margin/getData`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (err) {
    return err;
  }
};

export const editMarginValue = async (id, marginData, token) => {
  const response = await axios.put(
    `${PUBLIC_API_URL}/admin/margin/updateData/${id}`,
    marginData,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return response.data;
};

export const removeData = async (id) => {
  try {
    let token = localStorage.getItem('admin_token');
    const response = await axios.delete(
      `${PUBLIC_API_URL}/admin/margin/remove/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};
