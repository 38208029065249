import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "../forgotPassword";
import { useDispatch } from "react-redux";
import * as authActions from "../../store/action/authAction";
import { Button } from "react-bootstrap";
import "./index.scss";
import { ToastContainer } from "react-toastify";
import { BsEye } from "react-icons/bs";

const Login = () => {
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [error, setError] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [showPasswords, setShowPassword] = useState(true);

  const handleLogin = async (e) => {
    const formValidation = () => {
      const { email, password } = user;
      let formErrors = {};
      let isValid = true;
      const regex = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      const pwd = new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
      );
      if (!email) {
        isValid = false;
        formErrors["email"] = "Email is required";
      } else if (!regex.test(email)) {
        isValid = false;
        formErrors["email"] = "Please enter a valid email address";
      }
      if (!password) {
        isValid = false;
        formErrors["password"] = "Password is required";
      }
      setError(formErrors);
      return isValid;
    };

    if (formValidation()) {
      try {
        const data = {
          email: user.email,
          password: user.password,
        };
        dispatch(authActions.loginUser(data, navigate));
        setDisabledBtn(true);
      } catch (err) {
        return err.message;
      }
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setUser((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  useEffect(() => {
    setTimeout(() => {
      setDisabledBtn(false);
    }, 7500);
  }, [disabledBtn]);
  const showPassword = () => {
    setShowPassword(!showPasswords);
  };
  return (
    <div className="login-wrapper">
      <div className="portal-signup-contain">
        <div className="portal-side-left">
          <div className="portal-logo">
            <img src="images/logo.png" alt="" />
          </div>
          <div className="login-form">
            <div className="loginn">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleLogin(e);
                }}
              >
                <div className="loginbox">
                  <h2>Sign In</h2>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Email"
                      onChange={handleChange}
                    />

                    <span className="error">{error.email}</span>
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <div className="pas-word sign_pws">
                      <input
                        type={showPasswords ? "password" : "input"}
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                      />
                      <Button
                        className={
                          showPasswords
                            ? "password-btn notshow "
                            : "password-btn"
                        }
                        onClick={showPassword}
                      >
                        <BsEye />
                      </Button>
                    </div>
                    <span className="error">{error.password}</span>
                  </div>
                </div>
                <button
                  className="btn btn-primary btn-block"
                  disabled={disabledBtn}
                >
                  Login
                </button>
                <div className="forgetpassword">
                  <ForgotPassword />
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={3000} />
      </div>
    </div>
  );
};

export default Login;
