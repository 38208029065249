import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
const NoDataFound = () => {
  return (
    <Card
      className="no-datalisted"
      style={{
        width: '100%',
        backgroundColor: 'whiteSmoke',
        textAlign: 'center',
      }}
    >
      <CardContent>
        <Typography
          style={{ fontSize: 20, fontWeight: 500 }}
          color="textSecondary"
          gutterBottom
        >
          No data found
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NoDataFound;
