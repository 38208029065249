import React from 'react';
import { Button, Modal, ListGroup } from 'react-bootstrap';
import Loader from '../loader/orderLoader/index';

const OrderDetails = ({
  orderDetailsView,
  orderDetails,
  handleHideOrderDetails,
  getLatestOrderDetails,
  orderStatus,
  OrderLoader,
}) => {
  const capitalizeFirstLetter = (text) => {
    if (text && typeof text === 'string' && text.length > 0) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
    return text;
  };

  const status = orderStatus ? orderStatus?.status : orderDetails?.status;
  const capitalizedStatus = capitalizeFirstLetter(status);

  return (
    <Modal
      show={orderDetailsView}
      className="user-modal userlistmodal"
      onHide={handleHideOrderDetails}
    >
      <div className="login1">
        <Modal.Header>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        {OrderLoader == false ? (
          <Modal.Body>
            <ListGroup className="listdetail">
              <ListGroup.Item>
                <span className="name">Order Id :</span>
                <span className="value">
                  {orderStatus ? orderStatus?.id : orderDetails?.id}
                </span>
              </ListGroup.Item>
              <ListGroup.Item>
                <span className="name">Shipping :</span>
                <span className="value">
                  {orderStatus ? orderStatus?.shipping : orderDetails?.shipping}
                </span>
              </ListGroup.Item>
              {capitalizedStatus && (
                <ListGroup.Item>
                  <span className="name">Shipping Status:</span>
                  <span className="value">{capitalizedStatus}</span>
                </ListGroup.Item>
              )}
              <ListGroup.Item>
                <span className="name">Shipping Days :</span>{' '}
                <span className="value">
                  {orderStatus
                    ? orderStatus?.shipping_service_name
                    : orderDetails?.shipping_service_name}
                </span>
              </ListGroup.Item>
            </ListGroup>
          </Modal.Body>
        ) : (
          <div
            className="no-data d-flex align-items-center justify-content-center"
            style={{ height: '240px' }}
          >
            <Loader />
          </div>
        )}
        {OrderLoader == false ? (
          <Modal.Footer>
            <Button variant="info" onClick={handleHideOrderDetails}>
              Cancel
            </Button>
            <Button
              variant="info text-light"
              onClick={() => getLatestOrderDetails(orderDetails?.id)}
            >
              Status update
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button variant="info" disabled>
              Cancel
            </Button>
            <Button variant="info text-light" disabled>
              Status update
            </Button>
          </Modal.Footer>
        )}
      </div>
    </Modal>
  );
};

export default OrderDetails;
