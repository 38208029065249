import React from 'react';
import { Button, Modal, ListGroup } from 'react-bootstrap';

const UserDetailList = ({ viewUser, userSingleData, handleViewHideUser }) => {
  return (
    <Modal
      show={viewUser}
      className="user-modal userlistmodal"
      onHide={handleViewHideUser}
    >
      <div className="login1">
        <Modal.Header>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup className="listdetail">
            <ListGroup.Item>
              <span className="name">User Name :</span>
              <span className="value">
                {userSingleData?.userDetails?.userFullName}
              </span>
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="name">User Email :</span>
              <span className="value">
                {userSingleData?.userDetails?.userEmail}
              </span>
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="name">User Phone No :</span>{' '}
              <span className="value">
                +1 {userSingleData?.userDetails?.userPhoneNo}
              </span>
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="name">User City :</span>{' '}
              <span className="value">
                {userSingleData?.userDetails?.userCity}
              </span>
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="name">User Country :</span>{' '}
              <span className="value">
                {userSingleData?.userDetails?.userCountry}
              </span>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handleViewHideUser}>
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default UserDetailList;
