// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginPop {
    width: 800px;
    background: #fff;
    border: 1px solid #dddfe2;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 1rem;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 20%;
    left: 30%;
  }
  
  .loginPop > .input1 {
    border-radius: 8px;
    border: 2px solid #dddfe2;
    outline: none;
    color: #1d2129;
    margin: 0.1rem 0;
    padding: 0.5rem 0.75rem;
    width: 92%;
    font-size: 1rem;
  }
  
  .loginPop > .button {
    background: #1877f2;
    border: 1px solid #1877f2;
    color: #fff;
    font-size: 1.25rem;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 8px;
    outline: none;
    cursor: pointer;
    width: 320px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/forgotPassword/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,mEAAmE;IACnE,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,QAAQ;IACR,SAAS;EACX;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;IACb,cAAc;IACd,gBAAgB;IAChB,uBAAuB;IACvB,UAAU;IACV,eAAe;EACjB;;EAEA;IACE,mBAAmB;IACnB,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,YAAY;EACd","sourcesContent":[".loginPop {\r\n    width: 800px;\r\n    background: #fff;\r\n    border: 1px solid #dddfe2;\r\n    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);\r\n    border-radius: 8px;\r\n    padding: 1rem;\r\n    align-items: center;\r\n    text-align: center;\r\n    position: absolute;\r\n    top: 20%;\r\n    left: 30%;\r\n  }\r\n  \r\n  .loginPop > .input1 {\r\n    border-radius: 8px;\r\n    border: 2px solid #dddfe2;\r\n    outline: none;\r\n    color: #1d2129;\r\n    margin: 0.1rem 0;\r\n    padding: 0.5rem 0.75rem;\r\n    width: 92%;\r\n    font-size: 1rem;\r\n  }\r\n  \r\n  .loginPop > .button {\r\n    background: #1877f2;\r\n    border: 1px solid #1877f2;\r\n    color: #fff;\r\n    font-size: 1.25rem;\r\n    padding: 0.5rem;\r\n    margin: 0.5rem 0;\r\n    border-radius: 8px;\r\n    outline: none;\r\n    cursor: pointer;\r\n    width: 320px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
